/**
 If the user's browser has the "Block third-party cookies and site data"
 checkbox set in Content Settings, storage access will be denied resulting on
 any attempt of accessing it throwing a reference error.
 */

let _isSessionStorageAvailable: boolean | null = null;
const isSessionStorageAvailable = () => {
  if (_isSessionStorageAvailable !== null) return _isSessionStorageAvailable;

  try {
    if (typeof sessionStorage === 'undefined')
      return (_isSessionStorageAvailable = false);
    sessionStorage.setItem('__test', 'test');
    sessionStorage.removeItem('__test');
    return (_isSessionStorageAvailable = true);
  } catch {
    return (_isSessionStorageAvailable = false);
  }
};

let _isLocalStorageAvailable: boolean | null = null;
const isLocalStorageAvailable = () => {
  if (_isLocalStorageAvailable !== null) return _isLocalStorageAvailable;

  try {
    if (typeof localStorage === 'undefined')
      return (_isLocalStorageAvailable = false);
    localStorage.setItem('__test', 'test');
    localStorage.removeItem('__test');
    return (_isLocalStorageAvailable = true);
  } catch {
    return (_isLocalStorageAvailable = false);
  }
};

export const hobbiiSessionStorage = {
  setItem: (keyName: string, keyValue: string) =>
    isSessionStorageAvailable() && sessionStorage.setItem(keyName, keyValue),
  getItem: (keyName: string) =>
    isSessionStorageAvailable() ? sessionStorage.getItem(keyName) : null,
  removeItem(keyName: string) {
    isSessionStorageAvailable() && sessionStorage.removeItem(keyName);
  }
};

export const hobbiiLocalStorage = {
  setItem: (keyName: string, keyValue: string) =>
    isLocalStorageAvailable() && localStorage.setItem(keyName, keyValue),
  getItem: (keyName: string) =>
    isLocalStorageAvailable() ? localStorage.getItem(keyName) : null,
  removeItem(keyName: string) {
    isLocalStorageAvailable() && localStorage.removeItem(keyName);
  }
};
