import Bugsnag from '@bugsnag/js';
import { defineStore } from 'pinia';

import { entityTypes } from '@/shared/constants/product';
import { contextualError } from '@/shared/utils/error';

import {
  queryPatternBySku,
  queryTabCounters
} from '../../graphql/gateway/queries';
import { Strategy } from '../../strategies';
import { useConfigStore } from './config';
import { useProductOptionsStore } from './product-options';
import { useProductQuantitiesStore } from './product-quantities';
import { useUserStore } from './user';

export const useProductStore = defineStore('product', {
  state: () => ({
    productId: null,
    productName: null,
    variantId: null,
    legacyId: null,
    strategy: Strategy.ProductPage,
    purchasable: true,
    variantsStockInfo: null,
    entityType: entityTypes.PRODUCT,
    mainImageUrl: null,
    price: {
      initial_unit: null, // unit price without discount
      total: null,
      unit: null,
      before: null,
      savings: null,
      currentDiscountPercent: null
    }
  }),
  getters: {
    isPattern() {
      return this.entityType === entityTypes.PATTERN;
    },
    isProduct() {
      return this.entityType === entityTypes.PRODUCT;
    }
  },
  actions: {
    setStrategy(strategy) {
      const strategyName = Object.keys(Strategy).find(
        (s) => Strategy[s] == strategy
      );
      this.strategy = Strategy[strategyName];
    },
    updatePriceByQuantity() {
      const quantityStore = useProductQuantitiesStore();

      if (quantityStore.bulkDiscounts.length && quantityStore.quantity > 1) {
        this.calculateNewPricesByQuantityChange();
        this.updateDOMPricesAndSavings();
      } else {
        const optionsStore = useProductOptionsStore();
        const targetVariant = optionsStore.targetVariant;
        this.calculateNewPricesByVariantChange(targetVariant);
        this.updateDOMPricesAndSavings();
      }
    },
    updatePriceByVariant(variant) {
      const quantityStore = useProductQuantitiesStore();
      const calculateViaQuantity =
        quantityStore.bulkDiscounts.length && quantityStore.quantity > 1;

      if (calculateViaQuantity) {
        this.calculateNewPricesByQuantityChange();
        this.updateDOMPricesAndSavings();
      } else {
        this.calculateNewPricesByVariantChange(variant);
        this.updateDOMPricesAndSavings();
      }
    },
    calculateNewPricesByVariantChange(option) {
      const isOnDiscount = option.priceBefore !== option.price;

      this.price.initial_unit = option.price;

      if (isOnDiscount) {
        this.price.unit = option.price;
        this.price.before = option.priceBefore;
        this.price.savings = option.priceBefore - option.price;
      } else {
        this.price.unit = option.priceBefore;
        this.price.before = null;
        this.price.savings = null;
      }
    },
    calculateNewPricesByQuantityChange() {
      const quantityStore = useProductQuantitiesStore();
      const optionsStore = useProductOptionsStore();
      const selectedBulkDiscount = quantityStore.selectedBulkDiscount();

      if (!selectedBulkDiscount) return;

      const selectedVariant = optionsStore.activeOption;
      const targetVariantId = window.Hobbii?.productPage?.targetVariantId;
      const targetVariant = targetVariantId
        ? optionsStore.options.find((variant) => variant.id === targetVariantId)
        : optionsStore.options[0];

      const variant = selectedVariant || targetVariant;

      if (selectedBulkDiscount.discount_percentage) {
        const discount =
          selectedBulkDiscount.price_before - selectedBulkDiscount.price_unit;
        this.price.unit = selectedBulkDiscount.price_unit;
        this.price.before = variant.priceBefore || variant.compare_at_price;
        this.price.initial_unit = variant.price;
        this.price.savings = discount;
      } else {
        this.price.before = null;
        this.price.savings = null;
      }
    },
    setInitialPriceFromDOM() {
      // Populate store with initial price data for use with bulk discounts
      const productPriceBefore = document.querySelector('.js-price-before');
      const productPriceWrapper = document.querySelector('.js-price-wrapper');

      if (productPriceBefore?.dataset.initialValue) {
        this.price.before = JSON.parse(
          productPriceBefore?.dataset.initialValue
        );
      }

      if (productPriceWrapper?.dataset.initialValue) {
        this.price.initial_unit = JSON.parse(
          productPriceWrapper?.dataset.initialValue
        );
      }
    },
    updateDOMPricesAndSavings() {
      const optionsStore = useProductOptionsStore();
      const configStore = useConfigStore();

      const productPriceBlock = document.querySelector('.js-price');
      const productPriceBefore = document.querySelector('.js-price-before');
      const productPriceWrapper = document.querySelector('.js-price-wrapper');
      const productPointsWrapper = document.querySelector('.js-points-wrapper');
      const productPriceSave = document.querySelector('.js-price-save');
      const productPriceFromLabel = document.querySelectorAll(
        '.js-price-from-label'
      );

      // 1. Update unit price and points
      productPriceWrapper.innerHTML = configStore.formattedPrice(
        this.price.unit
      );

      if (productPointsWrapper) {
        productPointsWrapper.innerHTML = configStore.calculatedPoints(
          this.price.unit
        );
      }

      const thereAreSavings = this.price.savings && this.price.before;

      // 2. Update price block
      if (thereAreSavings) {
        productPriceBlock.classList.add('special');
      } else {
        productPriceBlock.classList.remove('special');
      }

      // 3. Update discount prices
      // 3.1. update before price
      if (productPriceBefore) {
        if (thereAreSavings) {
          productPriceBefore.classList.remove('hidden');
          productPriceBefore.innerHTML = configStore.formattedPrice(
            this.price.before
          );
        } else {
          productPriceBefore.classList.add('hidden');
        }
      }

      // 3.2. update saving price
      if (productPriceSave) {
        if (this.price.savings) {
          productPriceSave.classList.remove('invisible');
          const spanToUpdate = productPriceSave.querySelector('span');
          if (spanToUpdate) {
            spanToUpdate.innerHTML = configStore.formattedPrice(
              this.price.savings
            );
          }
        } else {
          productPriceSave.classList.add('invisible');
        }
      }

      // 4. Update "from" label
      if (productPriceFromLabel.length > 0) {
        if (optionsStore.activeOption) {
          productPriceFromLabel.forEach((el) => el.classList.add('hidden'));
        } else {
          productPriceFromLabel.forEach((el) => el.classList.remove('hidden'));
        }
      }
    },
    setTabCounters() {
      const configStore = useConfigStore();

      const entity = window.Hobbii.productPage.isPattern
        ? entityTypes.PATTERN
        : entityTypes.PRODUCT;

      const entity_id = this.legacyId || this.productId;
      const queryArgs = {
        galleryContext: {
          kind: 'gallery',
          entity,
          entity_id,
          language: configStore.language
        },
        qaContext: {
          kind: 'qa',
          entity,
          entity_id,
          language: configStore.language
        }
      };

      return this.apolloClient.query(queryTabCounters(queryArgs));
    },
    async patternDownloadUrl(productSku, contactUUID, countryCode) {
      const userStore = useUserStore();
      if (this.purchasable && !userStore.isLogged) return null;

      try {
        const patternBySkuResponse = await this.apolloClient.query(
          queryPatternBySku(productSku, window.Shopify.locale, undefined, {
            contactUUID: contactUUID,
            countryCode: countryCode
          })
        );
        return patternBySkuResponse.data?.patternBySku?.url ?? null;
      } catch (error) {
        if (Bugsnag.isStarted()) {
          error.message = `[DATA-ERROR] - Error on queryPatternBySku (SKU: "${productSku}", countryCode: "${countryCode}")`;
          Bugsnag.notify(contextualError('useProductStore', error));
        }
        return null;
      }
    }
  }
});
