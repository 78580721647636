import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { CommunityAuthor } from '@/__generated__/gateway/graphql';
import { userCookies } from '@/shared/constants/cookies';
import { setCookie } from '@/shared/utils/cookie';
import { parseJwt } from '@/shared/utils/jwt';
import {
  hobbiiLocalStorage,
  hobbiiSessionStorage
} from '@/shared/utils/web-storage';

// TODO: Could this be determined by the creating service?
export type Customer = {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
};

type OwnProfile = Omit<CommunityAuthor, 'is_following'>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidCustomer = (data: any): data is Customer =>
  data &&
  typeof data.uuid === 'string' &&
  typeof data.first_name === 'string' &&
  typeof data.last_name === 'string' &&
  typeof data.email === 'string';

export const useUserStore = defineStore('user', () => {
  const _persistedToken = ref<string | null>(
    hobbiiLocalStorage.getItem(userCookies.C_ID)
  );

  const _token = computed<string | null>({
    set: (value: string | null) => {
      _persistedToken.value = value;
      if (value) {
        hobbiiLocalStorage.setItem(userCookies.C_ID, value);
      } else {
        hobbiiLocalStorage.removeItem(userCookies.C_ID);
        setCookie(userCookies.C_ID, '', -5);
      }
    },
    get: () => _persistedToken.value
  });

  const customer = computed<Customer | null>(() => {
    if (!_token.value) {
      return null;
    }
    const obj = parseJwt(_token.value);
    const customerData = {
      uuid: obj['cognito:username'],
      first_name: obj.name,
      last_name: obj.family_name,
      email: obj.email
    };

    if (isValidCustomer(customerData)) {
      return customerData;
    }

    console.error('Incomplete customer data from token');
    return null;
  });

  const authenticated = computed(() => !!customer.value);
  /**
   * @deprecated Use authenticated instead
   */
  const isLogged = computed(() => authenticated.value);

  const setCustomer = (token?: string) => {
    if (token) {
      _token.value = token;
      hobbiiSessionStorage.setItem(userCookies.C_ID, token);
    } else {
      cleanCustomer();
    }
  };

  const cleanCustomer = () => {
    _token.value = null;
    _communityProfile.value = null;
    hobbiiSessionStorage.removeItem(userCookies.C_ID);
  };

  // TODO: Temp solution, while userStore & apolloClient is still tangled
  const _communityProfile = ref<OwnProfile | null>();
  const communityProfile = computed(() => _communityProfile.value);
  const setCommunityProfile = (profile: OwnProfile) => {
    _communityProfile.value = profile;
  };

  // ToS (ToC)
  const _termsOfServiceAgreed = ref<boolean | null>(null);
  const setTermsOfServiceAgreement = (agreed: boolean) => {
    _termsOfServiceAgreed.value = agreed;
    _showTermsOfServiceAgreementModal.value = false;
  };

  const _showTermsOfServiceAgreementModal = ref(false);
  const showingTermsOfServiceAgreementModal = computed(
    () => _showTermsOfServiceAgreementModal.value
  );

  const openAndAwaitTermsOfServiceAgreementModal = () => {
    _showTermsOfServiceAgreementModal.value = true;

    return new Promise((resolve) => {
      // watch for one change
      const stopWatching = watch(
        _showTermsOfServiceAgreementModal,
        (showing) => {
          if (!showing) {
            stopWatching();
            resolve(_termsOfServiceAgreed.value);
          }
        }
      );
    });
  };

  const closeTermsOfServiceAgreementModal = () => {
    _showTermsOfServiceAgreementModal.value = false;
  };

  return {
    authenticated,
    isLogged,
    customer,
    setCustomer,
    cleanCustomer,
    communityProfile,
    setCommunityProfile,
    openAndAwaitTermsOfServiceAgreementModal,
    showingTermsOfServiceAgreementModal,
    closeTermsOfServiceAgreementModal,
    setTermsOfServiceAgreement
  };
});
